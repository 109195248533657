<template>
    <div>Esporta Stati</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Documenti", route: "/document" },
            { title: "Stati", route: "/document/state/index" },
            { title: "Esporta Stati" }
        ]);
    }
};
</script>
